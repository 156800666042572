<template>
  <div>
    <div class="d-flex justify-center mb-6">
      <svg-icon icon="page-not-found" slot="append" class="not-found-icon" />
    </div>
    <div class="d-flex justify-center mb-6">
      <h1 class="font-weight-black indigo--text  darken-4 ">
        {{ $t('content.page_not_found_head') }}
      </h1>
    </div>
    <div class="d-flex justify-center mb-6">
      <p class="text-center indigo--text">
        {{ $t('content.page_not_found_message') }}
      </p>
    </div>

    <div class="d-flex justify-center mb-6">
      <v-btn @click="goToNotification" x-large depressed class="indigo white--text px-4">
        {{ $t('content.back_to_notification') }}
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageNotFound',
  methods: {
    goToNotification() {
      this.$router.push({ name: 'Notifications' }).catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.not-found-icon {
  margin-top: -2%;
}
@media only screen and (max-width: 967px) {
  .not-found-icon {
    margin-top: -6%;
  }
}
</style>
